import React from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../../components/Layout";
import moment from "moment";
import { BUY_FLORA, GET_APP } from "../../assets/Links";
import DownloadAppBar from "../../components/Layout/DownloadAppBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import NoAuthorImage from "../../images/blogs/no-author.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  containerClass,
  downloadAppBanner,
  container,
  titleClass,
  bannerClass,
  otherArticleClass,
  hightlightTextClass,
  tableContainerClass,
  authorNameClass,
  authorPopupClass,
  authorImageClass,
  authorHeaderClass,
  authorNameInPopupClass,
  authorDescInPopupClass,
} from "./style.module.css";
import {
  SHOW_ANNOUCEMENT_BANNER,
  footer1,
  footer2,
  footer3,
} from "../../utils/constant";
import ShopPlants from "../../components/PlantDetail/ShopPlants";

const downloadBanner = `<a href=${GET_APP} target="_blank"><img src='https://media.graphassets.com/207mwI1TPS3ZTFfvgPVe' class='${downloadAppBanner}'></a>`;
const longBanner = `<a href=${BUY_FLORA} target="_blank"><img src='https://media.graphassets.com/aEwA46tgRRqVnMuIDh3a' class='${downloadAppBanner}'></a>`;
const wideBanner = `<a href=${BUY_FLORA} target="_blank"><img src='https://media.graphassets.com/DPnPdAreQ9qehRUvoKUb' class='${downloadAppBanner}'></a>`;
const tallBanner = `<a href=${BUY_FLORA} target="_blank"><img src='https://media.graphassets.com/Hs0UWM9RC47tlcsQA3TA' class='${downloadAppBanner}'></a>`;

const BlogPost = (props) => {
  const post = props?.data?.allGraphCmsPost?.edges?.[0]?.node;

  const modifyHtml = (html) => {
    let modifiedHtml = html;

    // app banner
    modifiedHtml = modifiedHtml?.replaceAll(
      /{{downloadAppBanner}}/g,
      downloadBanner
    );
    modifiedHtml = modifiedHtml?.replaceAll(/{{LongPodBanner}}/g, longBanner);
    modifiedHtml = modifiedHtml?.replaceAll(/{{WidePodBanner}}/g, wideBanner);
    modifiedHtml = modifiedHtml?.replaceAll(/{{TallPodBanner}}/g, tallBanner);

    modifiedHtml = modifiedHtml?.replaceAll(/{{footer-variant-1}}/g, footer1);
    modifiedHtml = modifiedHtml?.replaceAll(/{{footer-variant-2}}/g, footer2);
    modifiedHtml = modifiedHtml?.replaceAll(/{{footer-variant-3}}/g, footer3);

    // blue highlight box
    modifiedHtml = modifiedHtml?.replaceAll(
      "{{",
      `<div class='${hightlightTextClass}'>`
    );
    modifiedHtml = modifiedHtml?.replaceAll("}}", `</div>`);

    // table
    modifiedHtml = modifiedHtml?.replaceAll(
      "<table>",
      `<div class='${tableContainerClass}'><table><h3>Find out more about it</h3>`
    );
    modifiedHtml = modifiedHtml?.replaceAll("</table>", `</table></div>`);

    // modifiedHtml = modifiedHtml?.replaceAll('<strong>', '');
    // modifiedHtml = modifiedHtml?.replaceAll('</strong>', '');

    return modifiedHtml;
  };

  const otherArticles = props?.data?.otherPosts?.edges?.map((edge) => {
    return edge?.node;
  });

  const calculateReadingTime = () => {
    const wordsPerMinute = 200; // Average case.
    let result;

    let textLength = post?.content?.text?.split(" ")?.length; // Split by words
    if (textLength > 0) {
      let value = Math.ceil(textLength / wordsPerMinute);
      result = `${value} min read`;
    }

    return result;
  };

  const authorPopup = (
    <Popover placement="bottom">
      <Popover.Body className={authorPopupClass}>
        <div className={authorHeaderClass}>
          <LazyLoadImage
            src={post?.author?.picture?.url || NoAuthorImage}
            className={authorImageClass}
          />
          <div>
            <div>Author</div>
            <div className={authorNameInPopupClass}>{post?.author?.name}</div>
          </div>
        </div>
        <div className={authorDescInPopupClass}>{post?.author?.biography}</div>
      </Popover.Body>
    </Popover>
  );

  return (
    <Layout title={`Flora | ${post.title}`}>
      <div className="flex flex-col items-center">
        <div className="w-full xl:w-[1200px]">
          <div className="fixed w-full z-10 " style={{ bottom: "12px" }}>
            <DownloadAppBar />
          </div>
          <div
            className={containerClass}
            style={{ marginTop: SHOW_ANNOUCEMENT_BANNER ? "110px" : "80px" }}
          >
            <div className="mt-10 md:mt-10">
              <h1 className={titleClass}>{post?.title}</h1>

              {!!post?.author?.name && (
                <div className="text-gray mt-2 text-xl font-light">
                  Written by{" "}
                  <OverlayTrigger
                    trigger="hover"
                    placement="bottom"
                    delay={{ show: 100, hide: 200 }}
                    overlay={authorPopup}
                  >
                    <span className={authorNameClass}>
                      {post?.author?.name}
                    </span>
                  </OverlayTrigger>
                </div>
              )}

              <div className="text-gray mt-1 text-md font-light">
                {`${moment(post?.date).format(
                  "MMM DD, YYYY"
                )} · ${calculateReadingTime()}`}
              </div>

              {post?.tags?.length > 0 && (
                <div
                  className="flex mt-2 flex-wrap"
                  style={{
                    maxWidth: 800,
                    marginTop: 20,
                  }}
                >
                  {post?.tags?.map((tag) => (
                    <div
                      key={tag}
                      className="mb-5 bg-gradient-to-br from-[#78dd4a] to-[#38c7be] rounded-full hover:bg-gradient-to-bl md:block text-white md:whitespace-nowrap  md:focus:outline-none md:text-lg lg:text-base mr-2 text-center py-1 px-3"
                    >
                      {tag}
                    </div>
                  ))}
                </div>
              )}

              {!!post?.coverImage?.url && (
                <LazyLoadImage
                  src={post?.coverImage?.url}
                  alt={post?.coverImage?.fileName || "img"}
                  className={bannerClass}
                  loading="lazy"
                />
              )}

              <div
                id="htmlTemplate"
                className={`text-base mx-10  md:text-lg md:mr-20 ${container}`}
                dangerouslySetInnerHTML={{
                  __html: modifyHtml(post?.content?.html),
                }}
              ></div>
            </div>
          </div>
          <ShopPlants />
          <div className="text-2xl w-full p-5 md:px-48 font-bold md:text-5xl">
            Other Articles:
          </div>
          <div className="flex md:grid overflow-scroll w-full p-5 grid-cols-1  md:grid-cols-2 gap-[1.5rem] lg:grid-cols-4 gap-6 ">
            {(otherArticles || []).map((post, index) => (
              <div
                key={index}
                onClick={() => navigate(`/blogs/${post?.slug?.toLowerCase()}/`)}
                className={otherArticleClass}
              >
                <LazyLoadImage
                  className="w-80 h-48 rounded-xl object-cover md:w-[16rem] hover:scale-95 transition duration-200 cursor-pointer"
                  src={post?.coverImage?.url}
                  alt={post?.coverImage?.fileName || "blog-feature-img"}
                />
                <p className="text-[#2D3344] font-avenir900 font-extrabold  text-2xl mt-3 hover:underline">
                  {post?.title}
                </p>
                <p className="text-[#2D3344] font-light mt-2">
                  {moment(post?.date).format("MMM DD, YYYY")}
                </p>
              </div>
            ))}
          </div>
          {/* <div className="pb-4">
        <DownloadAppBar />
      </div> */}
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    allGraphCmsPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          content {
            html
            text
          }
          author {
            name
            biography
            picture {
              url
            }
          }
          title
          date
          tags
          coverImage {
            url
            fileName
          }
        }
      }
    }
    otherPosts: allGraphCmsPost(limit: 8) {
      edges {
        node {
          title
          slug
          date
          coverImage {
            url
          }
        }
      }
    }
  }
`;

export default BlogPost;
