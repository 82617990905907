// extracted by mini-css-extract-plugin
export var authorDescInPopupClass = "style-module--authorDescInPopupClass--ee7de";
export var authorHeaderClass = "style-module--authorHeaderClass--3ae79";
export var authorImageClass = "style-module--authorImageClass--dbbcd";
export var authorNameClass = "style-module--authorNameClass--6138d";
export var authorNameInPopupClass = "style-module--authorNameInPopupClass--160b2";
export var authorPopupClass = "style-module--authorPopupClass--79805";
export var bannerClass = "style-module--bannerClass--4fb22";
export var container = "style-module--container--9378f";
export var containerClass = "style-module--containerClass--992cd";
export var downloadAppBanner = "style-module--downloadAppBanner--1b84f";
export var hightlightTextClass = "style-module--hightlightTextClass--0d502";
export var otherArticleClass = "style-module--otherArticleClass--eb273";
export var tableContainerClass = "style-module--tableContainerClass--fba34";
export var titleClass = "style-module--titleClass--7ba6e";